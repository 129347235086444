import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Animation from '../images/ani-graph.inline.svg';
import Mapbox from '../components/mapbox';

const ContactPage = () => {
    return (
        <Layout>
            <Seo
                title="Contact"
                description="Life Insurance and Family Planning"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        Contact
                    </h1>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="600"
                        data-sal-easing="easeOutCubic"
                    >
                        Our Team is Always Happy to Talk
                    </p>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="contact">
                                <div className="contact__item">
                                    <h1>Location</h1>
                                    <p>660C Newcastle St, Leederville WA 6007</p>
                                </div>
                                <div className="contact__item">
                                    <h1>Phone</h1>
                                    <p>
                                        <a href="tel:6323 8691">
                                            +61 8 6323 8691
                                        </a>
                                    </p>
                                    <p>
                                        <a href="tel:9284 1811">
                                            +61 8 9284 1811
                                        </a>
                                    </p>
                                </div>
                                <div className="contact__item">
                                    <h1>Email</h1>
                                    <p>
                                        <a href="mailto: ashley@lifespanwa.com.au">
                                            admin@lifespanwa.com.au
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="map">
                <Mapbox />
            </div>
        </Layout>
    );
};

export default ContactPage;