import React from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = process.env.GATSBY_MAPBOX_API_KEY;

class Mapbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lng: 115.84273782877189,
            lat: -31.93764299591159,
            zoom: 13,
        };
    }

    componentDidMount() {
        const map = new mapboxgl.Map({
            container: this.mapContainer,
            style: 'mapbox://styles/visns-studio/ckmx53lcu065c17mnnije3yl0',
            center: [this.state.lng, this.state.lat],
            zoom: this.state.zoom,
        });

        const el = document.createElement('div');
        el.className = 'marker';
        new mapboxgl.Marker(el, { anchor: 'bottom' })
            .setLngLat([this.state.lng, this.state.lat])
            .addTo(map);

        map.scrollZoom.disable();
        map.addControl(new mapboxgl.NavigationControl());
    }

    render() {
        return (
            <div
                ref={(el) => (this.mapContainer = el)}
                className="mapContainer"
            />
        );
    }
}

export default Mapbox;
